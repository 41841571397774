<template>
  <section
    class="blog-teaser part"
    :class="[ payload.optionen, payload.hintergrund ]"
  >
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-7 md:offset-1">
          <Go v-if="showBlogLink" :to="payload.blog.link">
            {{ payload.blog.linkText }}
          </Go>
          <h2 v-html="payload.titel" />
        </div>
        <div class="col-12 md:col-10 md:offset-1">
          <slider
            v-slot="{ item }"
            class="slider"
            :items="payload.articles"
          >
            <blog-card
              class="card h-full"
              :image="item.vorschaubild"
              :timestamp="item.datum"
              :titel="item.titel"
              :text="item.vorschautext"
              :button-text="item.buttonText"
              :button-link="item.link"
            />
          </slider>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';

export default {
  props: { payload: { type: Object, default: () => ({}) } },
  setup(props) {
    return {
      showBlogLink: computed(() => props.payload.optionen.includes('showBlogLink')),
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.blog-teaser {
  @apply bg-secondary;

  @include responsive('padding', px(60) 0 px(40) 0, px(141) 0 px(117) 0);

  &.orange {
    @apply bg-primary;
    @apply text-dark-orange;
  }
}

h2 {
  @include responsive('margin-bottom', px(60), px(81));
}

.slider {
  overflow: visible;
}

.card {
  @apply text-dark-gray;
}
</style>
